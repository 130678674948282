.container {
	padding: 70px 9vw;
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 100vh;
}

.formContainer {
	padding-top: 50px;
}

.formContainer h1 {
	margin-bottom: 20px;
}

.formContainer input,
.formContainer textarea {
	border: 1px solid grey;
	border-radius: 10px;
	height: 45px;
	width: 100%;
	padding: 0 10px;
}

.formContainer input:focus,
.formContainer textarea:focus {
	outline: none;
}

.formContainer textarea {
	padding: 10px;
	resize: vertical;
	height: 100px;
}

.form {
	display: grid;
	grid-template-columns: 1fr;
	gap: 20px;
}

.phoneNumberContainer {
	display: flex;
	border: 1px solid grey;
	border-radius: 10px;
	overflow: hidden;
}

.phoneNumberContainer select {
	border: none;
	padding: 10px;
}

.phoneNumberContainer input {
	border: none;
	padding: 0 10px;
}

.phoneNumberContainer input:focus {
	outline: none;
	border: none;
}

.otherContainer {
}

.submitBtn {
	height: 45px;
	width: 200px;
	margin: 0 auto;
	border-radius: 10px;
	border: 2px solid #ffa552;
	background: transparent;
	cursor: pointer;
	transition: 0.5s;
}

.submitBtn:hover {
	background: #ffa552;
	color: white;
}

@media only screen and (max-width: 690px) {
	.container {
		grid-template-columns: 1fr;
	}
}
