.hero_main {
  height: 75vh;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  padding-inline: 9vw;
  border-bottom: 3px solid #381828;
}

.text_area {
  /* border: 1px solid black; */
  width: 45vw;
  /* min-width:300px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.text_area_inside {
  height: max-content;
  /* border: 1px solid red; */
}

.text_area_inside h3 {
  font-size: 2.5vw;
  font-weight: 500;
  margin-bottom: 1em;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.6px;
  word-spacing: 2px;
  color: #381828;
}

.text_area_inside p {
  font-size: 1.15vw;
  padding-inline: 10px;
  text-align: center;
  line-height: 25px;
  overflow: hidden;
  word-spacing: 1px;
  font-weight: 300;
}

.btn {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.btn button {
  height: 30px;
  width: 100px;
  border-radius: 20px;
  border: 1.5px solid orange;
  color: orange;
  background-color: white;
  cursor: pointer;
}

.btn button:hover {
  background: orange;
  color: white;
}

.image_area {
  /* border: 1px solid red; */
  /* border-radius: 18px; */
  width: 40vw;

  padding: 5vw;
  overflow: hidden;
}
.image_area img {
  height: 100%;
  width: 100%;
  border-radius: 12%;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 480px) {
  .hero_main {
    position: relative;
    flex-direction: column;
    align-items: center;
    padding-left: 3vw;
    padding-right: 3vw;
    padding-bottom: 20px;
  }

  .text_area {
    width: 100%;
    flex-grow: 1;
  }

  .image_area {
    flex-grow: 1;
    width: 100%;
    padding: 0;
    margin-top: 15px;
  }

  .text_area_inside {
  }

  .text_area_inside h3 {
    font-size: 28px;
  }

  .text_area_inside p {
    font-size: 18px;
  }

  .image_area img {
    border-radius: 15px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
}
