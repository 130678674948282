.content_container {
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  /* background: white; */
  margin: 0 auto;
  /* transform: translateY(-200px); */
  font-size: 18px;
  color: #023033;
}

.mainContainer {
  display: grid;
  grid-template-columns: 1fr 350px;
  padding: 50px 3vw 50px 3vw;
  gap: 45px;
}

.content_container {
  border-right: 2px solid rgb(210, 210, 210);
  padding-right: 45px;
}

.content_quill p {
  white-space: pre-line !important;
  list-style: inside;
  /* hyphens: auto; */
}

.content_quill a:link {
  color: blue;
  text-decoration: underline;
}

.content_quill a:hover {
  color: rgb(0, 0, 138);
}

.content_quill a:visited {
  color: purple;
  text-decoration: underline;
}

.ad_container {
  /* background: green; */
  display: grid;
  grid-template-columns: 1fr;
  gap: 50px;
  height: fit-content;
}

.content_quill img {
  max-width: 100%;
  height: auto;
  /* aspect-ratio: 16/9; */
  object-fit: cover;
  object-position: left;
}

.mob_ad_1,
.mob_ad_2,
.mob_ad_3 {
  display: none;
}

@media (max-width: 780px) {
  .content_container {
    /* width: calc(100vw - 6vw); */
    font-size: 16px;
    border-right: none;
    padding-right: 0px !important;
  }

  .ad_container {
    display: none;
  }
  .content_quill img {
    height: fit-content;
    width: 100% !important;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 10px;
    object-fit: cover;
    object-position: center;
  }

  .content_quill ul {
    list-style: inside;
  }
  .mainContainer {
    grid-template-columns: 1fr;
    padding: 10px 4vw;
  }

  .content_container > div {
    margin-inline: auto;
  }

  .mob_ad_1,
  .mob_ad_2,
  .mob_ad_3 {
    display: block;
    width: calc(100vw - 12vw);
    height: fit-content;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #d95858;
    margin-top: 20px;
    position: relative;
  }

  .mob_ad_1 a,
  .mob_ad_2 a,
  .mob_ad_3 a {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
  }

  .mob_ad_1 h4,
  .mob_ad_2 h4,
  .mob_ad_3 h4 {
    font-size: 1.1rem !important;
    padding: 5px;
    font-weight: 500;
    text-align: center;
  }

  .mob_ad_3 {
    margin-bottom: 40px;
  }
}
