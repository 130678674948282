.container {
  height: 40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 29px;
  padding-bottom: 70px;
}

.container h1 {
  text-align: center;
}

.container p {
  font-size: 20px;
  line-height: 44px;
  letter-spacing: 0.56px;
  width: 60%;
  text-align: center;
}

@media (max-width: 480px) {
  .container {
    padding: 70px 3vw 0 3vw;
  }
  .container p {
    width: 95%;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .container {
    padding: 70px 3vw 0 3vw;
  }
  .container p {
    width: 95%;
  }
}
