.container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	padding: 50px 9vw;
	gap: 20px;
}

@media (max-width: 480px) {
	.container {
		grid-template-columns: 1fr;
		padding: 50px 3vw;
	}
}

@media (min-width: 481px) and (max-width: 768px) {
	.container {
		grid-template-columns: 1fr;
		padding: 50px 3vw;
	}
}
