.page_main,
.page_main_section2 {
  height: fit-content;
  width: 100vw;
  padding: 50px 9vw;
  display: grid;
  grid-template-columns: 60% 1fr;
  gap: 20px;
  justify-content: space-between;
  background-color: #c4d6b0;
  align-items: center;
  border-top: 3px solid #381828;
  border-bottom: 3px solid #381828;
}

.page_main_section2 {
  grid-template-columns: 1fr 60%;
}

.image_component,
.image_component_section2 {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: url("../Assets/puppy123.jpg");
  background-size: cover;
  background-position: center;
  border-radius: 18px;
}

.ad_container {
  height: fit-content;
  position: relative;
}

.ad_container > p {
  position: absolute;
  top: -18px;
  font-size: 12px;
  left: 50%;
  transform: translateX(-50%);
}

.text_area {
  /* border: 1px solid black; */
  width: 100%;
  height: 100%;
  padding: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
}

.background_gradient_for_ads {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 212, 255, 0) 100%
  );
}

.ads {
  display: flex;
  flex-direction: column;
  height: fit-content;
  gap: 20px;
}

.ad_1,
.ad_2 {
  width: 100%;
  /* max-height: 240px; */
  aspect-ratio: 9/5 !important;
  flex-grow: 1;
  border-radius: 18px;
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 10px;
  color: white;
  overflow: hidden;
  background-size: cover !important;
  background-position: center !important;
}

.ad_1 p,
.ad_2 p {
  z-index: 10;
  text-transform: capitalize;
  font-size: 1rem;
  color: white;
}

.ad_1 a,
.ad_2 a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.text_area_inside h3,
.text_area_inside_section2 h3 {
  font-size: 2.7rem;
  font-weight: 500;
  margin-bottom: 1em;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.6px;
  word-spacing: 2px;
  line-height: 3rem;
}

.text_area_inside p {
  font-size: 1.15vw;
  text-align: center;
  line-height: 25px;
  overflow: hidden;
  white-space: pre-wrap;
  font-weight: 300;
  color: white;
}

.text_area_inside button:hover {
  background: black !important;
  color: white !important;
}

.text_area_inside_section2 button:hover {
  background: white !important;
  color: #ffa552 !important;
}

.page_main_section2 {
  background-color: #ffa552;
}

.image_component_section2 {
  background: url("../Assets/cat-pexel.jpg");
}

.text_area_section2 {
  /* border: 1px solid black; */
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text_area_inside_section2 p {
  font-size: 1.15vw;
  text-align: center;
  line-height: 25px;
  overflow: hidden;
  word-spacing: 1px;
  font-weight: 300;
}

@media (max-width: 480px) {
  .ad_container {
    margin-top: 30px;
    height: fit-content;
  }

  .page_main,
  .page_main_section2 {
    grid-template-columns: 1fr;
  }

  .page_main {
    padding: 30px 3vw;
    flex-direction: column;
  }

  .image_component_section2 {
    grid-row: 1/2;
  }
  .page_main_section2 {
    padding: 30px 3vw;
    flex-direction: column-reverse;
  }

  .image_component,
  .image_component_section2 {
    height: fit-content;

    width: 100%;
  }

  .text_area,
  .text_area_section2 {
    width: 100%;
    padding: 30px 10px;
  }

  .text_area_inside,
  .text_area_inside_section2 {
    width: 100%;
  }

  .text_area_inside h3,
  .text_area_inside_section2 h3 {
    text-align: center;
    line-height: 30px;
    font-size: 28px;
    margin-bottom: 10px;
  }

  .text_area_inside p,
  .text_area_inside_section2 p {
    font-size: 18px;
    text-align: start;
    word-wrap: break-word;
    text-align: center;
  }

  .ads {
    height: fit-content;
  }

  .ad_1,
  .ad_2 {
    aspect-ratio: 16/13 !important;
  }
}
