.card_container {
  width: 330px;
  aspect-ratio: 1/1;
  background-position: center;
  background-size: cover;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
}

.card_container img {
  height: 100%;
  width: 100%;
  transition: 0.5s;
}

.card_container:hover img {
  scale: 1.1;
}

.card_content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 50.37%;
  background: linear-gradient(
    180.08deg,
    rgba(38, 38, 38, 0.4) 32.32%,
    rgba(43, 43, 43, 0) 99.93%
  );
  border-radius: 15px;
  padding: 40px 30px;
  color: white;
}

.card_content p:first-child {
  font-size: 1.3rem !important;
  font-weight: 800;
  letter-spacing: 0.05rem;
  line-height: 1.7rem;
  opacity: 0.9;
}

.card_content p:last-child {
  text-decoration: underline;
}

@media (max-width: 480px) {
  .card_container {
    width: 100%;
    margin-left: 0;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .card_container {
    margin-left: 3vw;
  }
}
