.hero {
  height: 20vh;
  background-color: #c4d6b0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
}

.content {
  height: fit-content;
  position: relative;
}

.page {
  width: 70vw;
  position: relative;
  margin-inline: auto;
  text-align: justify;
  margin-top: calc(20vh - 70px);
  margin-bottom: calc(20vh - 70px);
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.page ul {
  list-style: inside;
}

.page div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media (max-width: 480px) {
  .hero {
    font-size: 1.5rem;
  }
  .page {
    font-size: 0.8rem;
  }
}
